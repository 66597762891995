<template>
  <div
    v-if="loaded"
    id="footer"
    class="arkaplan text-center"
    style="position:relative;z-index:1"
  >
    <v-container>
      <v-row>
        <v-col cols="12">
          <v-card class="mx-auto py-9" elevation="0" color="transparent">
            <v-card-title class="justify-center">
              <img
                class="footer-img"
                v-if="profil.logoFooter"
                :src="profil.logoFooter"
              />
            </v-card-title>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <div
      class="secondary d-flex flex-column flex-sm-row pa-3 align-start justify-space-between  "
    >
      <div
        class="d-flex flex-column flex-sm-row align-start text-caption info--text"
      >
        <div class="pr-0 pr-sm-3">
          {{ profil.adres }}
        </div>
        <div class="">
          {{ profil.tel }}
        </div>
      </div>
      <div class="text-caption info--text">
        <ul
          class="d-flex flex-column align-start flex-sm-row pa-0 pt-6 pt-sm-0"
          style="list-style:none"
        >
          <li class="pr-0 pr-sm-3">
            <a @click="dialogAc(0)" class="info--text">AGB</a>
          </li>
          <li class="pr-0 pr-sm-3">
            <a @click="dialogAc(1)" class="info--text">Datenschutzerklärung</a>
          </li>
          <li>
            <a @click="dialogAc(2)" class="info--text">Impressum</a>
          </li>
        </ul>
      </div>
    </div>

    <div v-if="yazilar.length > 0">
      <v-dialog v-model="dialogKural" scrollable width="600">
        <v-card>
          <v-card-title class="text-h5 ">
            <v-spacer />
            <v-btn fab small elevation="0" @click="dialogKural = false">
              <v-icon size="21">
                mdi-close
              </v-icon>
            </v-btn>
          </v-card-title>
          <v-card-text class="white">
            <div class="black--text" v-html="yazilar[index].icerik"></div>
          </v-card-text>
        </v-card>
      </v-dialog>
    </div>
  </div>
</template>

<script>
import { get, sync } from "vuex-pathify";
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";

import { veri } from "./../../db";
gsap.registerPlugin(ScrollTrigger);
export default {
  name: "Titles",
  data: () => ({
    profil: {},
    yazilar: [],
    dialogKural: false,
    index: 0
  }),
  firebase: {
    profil: veri.ref("profil"),
    yazilar: veri.ref("yazilar")
  },
  created() {
    setTimeout(() => {
      gsap.timeline({
        scrollTrigger: {
          trigger: "#footer",
          start: "top bottom",
          end: "center 0",
          ease: "circ.out ",
          markers: false,
          onEnter: self => {
            // console.log("onEnter");
            gsap.to("#gizliBaslik,#gizliSepet,.arkaPlan", {
              opacity: 0
            });
          },
          onLeaveBack: self => {
            // console.log("onLeaveBack");
            gsap.to("#gizliBaslik,#gizliSepet,.arkaPlan", {
              opacity: 1
            });
          }
        }
      });
    }, 1000);
  },
  computed: {
    ...sync("app", ["loaded"])
  },
  methods: {
    dialogAc(item) {
      this.index = item;
      this.dialogKural = true;
    }
  }
};
</script>

<style lang="scss" scoped>
.footer-img {
  width: 100%;
  max-width: 500px;
}
</style>
